import React from "react";
import {NavLink, Route, Routes, BrowserRouter} from "react-router-dom";
import Content from './Content';
import Imprint from "./Imprint";
import Privacy from "./Privacy";
import Thg from "./Thg";

function Main() {
    return (
        <BrowserRouter>
            <div className="container navig bgwhite">
                <div id="navig">
                    <div className="navcontainer">
                        <div id="logo">
                            <a href="/#pilotcharger" title="Pilot Charger"><img
                                src="images/Pilot_Charger_logo_blue.jpg"/></a>
                        </div>
                        <nav id="navigitems">
                            <div id="navigcontainer">
                                <a id="hamburger" href="javascript:toggleMobileMenu();" className="icoham">
                                    <svg className="icoham">
                                        <use href="#icomenu" className="icoham"/>
                                    </svg>
                                </a>
                                <ul id="naviglinks" className="naviglinks">
                                    <li><a href="/#offer">Unser Angebot</a></li>
                                    <li><a href="/#solution">Unsere Lösung</a></li>
                                    <li><a href="/#challenge">Die Herausforderung</a></li>
                                    <li><a href="/#pilotcharger">Der Pilot Charger</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>


            <Routes>
                <Route path="/" element={<Content/>}/>
                <Route path="privacy" element={<Privacy/>}/>
                <Route path="imprint" element={<Imprint/>}/>
                <Route path="thg" element={<Thg/>}/>
            </Routes>


            <div className="container footer bgblack">
                <div className="content">
                    <div className="item itemcols normaltxt lastrow padding-top-xs padding-bottom-xxs">
                        <div className="col1">
                            <NavLink to="/privacy" className="footerlink">Datenschutz</NavLink>
                            <NavLink to="/imprint" className="footerlink">Impressum</NavLink>
                        </div>
                    </div>
                </div>
            </div>

        </BrowserRouter>
    );
}

export default Main;
