import React, { Component } from "react";

class Imprint extends Component {
    render() {
        return (
            <div className="container bggrey">
                <div className="content">
                    <div className="item padding-top-s padding-bottom-s">
                        <div className="col1 leftalign">
                            <h3>Impressum</h3>
                            <p>
                                Impressum<br />
                                WertPiloten GmbH<br />
                                S&uuml;dliche M&uuml;nchner Str. 55<br />
                                82031 Gr&uuml;nwald<br /><br />

                                Amtsgericht M&uuml;nchen HRB 239838<br />
                                Gesch&auml;ftsf&uuml;hrer Marco Jansen<br /><br />

                                Telefon: +49 (0) 89-90429406<br />
                                E-Mail: <a href="mailto:info@wertpiloten.de">info@wertpiloten.de</a><br /><br />

                                PilotCharger ist eine Marke der WertPiloten GmbH
                            </p>
                            <p> <strong>Haftungshinweis:</strong><br />
                                Die Wertpiloten GmbH &uuml;bernimmt keine Garantie daf&uuml;r, dass die auf dieser Website bereitgestellten Informationen vollst&auml;ndig, richtig und in jedem Fall aktuell sind. Dies gilt auch f&uuml;r alle Verbindungen ("Links"), auf die diese Website direkt oder indirekt verweist. F&uuml;r den Inhalt der verlinkten Seiten sind ausschlie&szlig;lich deren Betreiber verantwortlich. In keinem Fall ist die Wertpiloten GmbH verantwortlich f&uuml;r Inhalte auf Internetseiten, die auf www.pilotcharger.de oder andere Seiten von WertPiloten GmbH verweisen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Imprint;