import React, {Component} from "react";

class Privacy extends Component {
    render() {
        return (
            <div className="container bggrey">
                <div className="content">
                    <div className="item padding-top-s padding-bottom-s">
                        <div className="col1 leftalign">
                            <h3>Datenschutzerkl&auml;rung</h3>

                            <p>
                                Datenschutz ist f&uuml;r uns sehr wichtig. Daher halten wir uns bei der Erhebung Verarbeitung und
                                Nutzung Ihrer personenbezogenen Daten an die gesetzlichen Bestimmungen des Bundesdatenschutzgesetzes
                                und des Telemediengesetzes. Wir m&ouml;chten Sie daher an dieser Stelle &uuml;ber unsere
                                Datenerhebung und Datenverwendung informieren.
                            </p>

                            <p>
                                Sie haben die M&ouml;glichkeit dieses Dokument auszudrucken oder zu speichern, indem sie
                                die &uuml;bliche Funktionalit&auml;t Ihres Internetdiensteprogramms, also in der Regel "Datei" -
                                "Speichern unter" nutzen.
                            </p>

                            <h4>1. Verantwortliche Stelle</h4>
                            <p>
                                Verantwortliche Stelle f&uuml;r die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten
                                ist die WertPiloten GmbH, S&uuml;dliche M&uuml;nchner Str. 55, 82031 Gr&uuml;nwald (nachfolgend:
                                Pilot Charger). Wenn Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch Pilot Charger widersprechen
                                wollen, k&ouml;nnen Sie Ihren Widerspruch formlos, per Brief, Fax oder E-Mail an folgende
                                Kontaktdaten senden:
                            </p>
                            <p>
                                WertPiloten GmbH, S&uuml;dliche M&uuml;nchner Str. 55, 82031 Gr&uuml;nwald<br/>
                                Telefon: +49 (0) 89-90429406<br/>
                                E-Mail: info@wertpiloten.de<br/>
                            </p>

                            <h4>2. Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten</h4>

                            <div class="midtxt">2.1 Personenbezogener Daten</div>
                            <p>
                                Personenbezogene Daten sind Angaben &uuml;ber sachliche oder pers&ouml;nliche Verh&auml;ltnisse
                                einer bestimmten oder bestimmbaren nat&uuml;rlichen Person. Hierunter fallen die Daten, die Sie uns
                                zur Aufnahme des Vertrages und bei der Registrierung auf dem Registrierungsformular der Pilot Charger
                                f&uuml;r die Vertragsleistungen Karten- und Dokumentenschutz zur Vertragsdurchf&uuml;hrung
                                mitteilen, n&auml;mlich Vorname, Name, Geburtsdatum, Schutzbriefnummer, Konto-, Kreditkartennummer,
                                SIM-Kartennummer, Telefonnummer, Personalausweis-nummer, Reisepassnummer, F&uuml;hrerscheinnummer,
                                Impfpassangaben, Organspenderausweis, Reiseunterlagen und die BahnCardnummer. Bei der IP-Adresse,
                                die im Falle des Aufrufs der Internetseiten von Pilot Charger registriert wird, handelt es sich ebenfalls
                                um ein personenbezogenes Datum.
                            </p>

                            <div class="midtxt">2.2 Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten</div>
                            <p>
                                Wir erheben, speichern und verarbeiten Ihre Daten f&uuml;r die gesamte Abwicklung der Service– und
                                Versicherungsleistungen des Vertrages einschlie&szlig;lich eventuell sp&auml;terer
                                Gew&auml;hrleistungen, f&uuml;r unsere Servicedienste, die technische Administration sowie eigene
                                Marketingzwecke. Ihre personenbezogenen Daten werden an Dritte nur weitergegeben oder
                                sonst &uuml;bermittelt, wenn dies zum Zwecke der Vertragsabwicklung oder Abrechnung erforderlich ist
                                oder Sie zuvor eingewilligt haben. Die so weitergegebenen Daten d&uuml;rfen von unseren
                                Dienstleistern lediglich zur Erf&uuml;llung ihrer Aufgabe verwendet werden. Eine anderweitige
                                Nutzung der Informationen ist nicht gestattet und erfolgt auch bei keinem der von uns betrauten
                                Dienstleister.
                            </p>
                            <p>
                                F&uuml;r den Abschluss des Vertrages ben&ouml;tigen wir Ihre korrekten Namens- , Adress- und
                                Zahldaten. Ihre E-Mail-Adresse ben&ouml;tigen wir, damit wir mit Ihnen kommunizieren k&ouml;nnen.
                                Das korrekte Geburtsdatum wird ben&ouml;tigt, um Vertragsschl&uuml;sse mit Minderj&auml;hrigen, ohne
                                dass deren gesetzlicher Vertreter zugestimmt hat, vom Vertragsschluss mit uns ausschlie&szlig;en zu
                                k&ouml;nnen.
                            </p>
                            <p class="bold">
                                Mit der Anmeldung für den Vertragsschluss willigen Sie gegen&uuml;ber der WertPiloten GmbH,
                                S&uuml;dliche M&uuml;nchner Str. 55, 82031 Gr&uuml;nwald in die Erhebung, Verarbeitung und Nutzung
                                Ihrer personenbezogenen Daten ein.
                            </p>
                            <p>
                                Ihre personenbezogenen Daten werden, sofern gesetzliche Aufbewahrungspflichten dem nicht
                                entgegenstehen, gel&ouml;scht, wenn Sie einen L&ouml;schungsanspruch geltend gemacht haben, wenn die
                                Daten zur Erf&uuml;llung des mit der Speicherung verfolgten Zwecks nicht mehr erforderlich sind oder
                                wenn ihre Speicherung aus sonstigen gesetzlichen Gr&uuml;nden unzul&auml;ssig ist.
                            </p>

                            <h4>3. Nutzung Ihrer Daten zu Werbezwecken</h4>
                            <p>
                                Neben der Verarbeitung Ihrer Daten zur Abwicklung des Vertrages durch Pilot Charger, nutzen wir Ihre Daten
                                auch um Ihnen bestimmte Produkte oder Dienstleistungen zu empfehlen, die Sie interessieren
                                k&ouml;nnten.
                            </p>
                            <p>
                                Der Verwendung Ihrer personenbezogenen Daten f&uuml;r Werbezwecke k&ouml;nnen Sie jederzeit
                                insgesamt oder f&uuml;r einzelne Ma&szlig;nahmen widersprechen, ohne dass hierf&uuml;r andere als
                                die &Uuml;bermittlungskosten nach den Basistarifen entstehen. Eine Mitteilung in Textform an die
                                unter Ziffer 1 genannten Kontaktdaten (.B. E-Mail, Fax, Brief) reicht hierf&uuml;r aus.
                            </p>

                            <h4>4. Produktempfehlungen per E-Mail</h4>
                            <p>
                                Als Kunde von Pilot Charger erhalten Sie Produktempfehlungen von uns per E-Mail. Wir wollen Ihnen auf
                                diese Weise Informationen &uuml;ber Produkte aus unserem Angebot zukommen lassen, die sie auf
                                Grundlage Ihres Vertragsschlusses bei uns interessieren k&ouml;nnten. Dabei richten wir uns streng
                                nach den gesetzlichen Vorgaben.
                            </p>
                            <p>
                                Sofern Sie keine Produktempfehlungen oder insgesamt keine werblichen Nachrichten mehr von uns
                                erhalten wollen, k&ouml;nnen Sie dem jederzeit widersprechen, ohne dass hierf&uuml;r andere als
                                die &Uuml;bermittlungskosten nach den Basistarifen entstehen. Eine Mitteilung in Textform an die
                                unter Ziffer 1 genannten Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht hierf&uuml;r aus.
                            </p>

                            <h4>5. Log-Dateien</h4>
                            <p>
                                Bei jedem Zugriff auf die Seiten von Pilot Charger werden Nutzungsdaten durch den jeweiligen Internet
                                Browser &uuml;bermittelt und in Protokolldateien, den so genannten Server-Logfiles, gespeichert. Die
                                dabei gespeicherten Datens&auml;tze enthalten die folgenden Daten: Website, von der aus die Datei
                                abgerufen wurde (Referrer-URL), IP-Adresse, Name der Datei, Datum und Uhrzeit des
                                Abrufs, &uuml;bertragene Datenmenge und Meldung &uuml;ber den Erfolg des Abrufs, sog. Web-Log).
                                Diese Zugriffsdaten verwenden wir ausschlie&szlig;lich in nicht personalisierter Form f&uuml;r die
                                stetige Verbesserung unseres Internetangebots und zu statistischen Zwecken. Die IP-Adresse der
                                Nutzer wird nach Beendigung der Nutzung gel&ouml;scht oder anonymisiert. Bei einer Anonymisierung
                                wird die IP-Adresse derart ge&auml;ndert, dass die Einzelangaben &uuml;ber pers&ouml;nliche oder
                                sachliche Verh&auml;ltnisse nicht mehr oder nur mit einem unverh&auml;ltnism&auml;&szlig;ig
                                gro&szlig;en Aufwand an Zeit, Kosten und Arbeitskraft einer bestimmten oder bestimmbaren
                                nat&uuml;rlichen Person zugeordnet werden k&ouml;nnen.
                            </p>

                            <h4>6. Auskunftsrechte</h4>
                            <p>
                                Nach &sect; 34 BDSG steht Ihnen ein jederzeitiges, unentgeltliches Auskunftsrecht &uuml;ber Ihre
                                gespeicherten Daten, sowie ggf. ein Recht auf Berichtigung, Sperrung oder L&ouml;schung von Daten
                                zu, was Sie unter den unter Ziffer 1 genannten Kontaktdaten (z.B. E-Mail, Fax, Brief) geltend machen
                                k&ouml;nne.
                            </p>
                            <p>
                                Den Schutz ihrer Daten nehmen wir sehr ernst. Um sicherzustellen, dass personenbezogene Daten nicht
                                an Dritte herausgegeben werden, richten Sie Ihre Anfrage bitte per E-Mail, Fax oder Post unter
                                eindeutiger Identifizierung Ihrer Person an:
                            </p>
                            <p>
                                WertPiloten GmbH<br/>
                                S&uuml;dliche M&uuml;nchner Str. 55<br/>
                                82031 Gr&uuml;nwald<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Privacy;