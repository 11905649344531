import React, {Component} from "react";

import useDimensions from "./Dimensions";

function Thg() {

    return (
        <iframe id={"thg"} src="https://pilotcharger.kauftdeinethg.de/#/sign-up?charging" frameBorder={0} height={useDimensions().height - 210} width={"100%"}></iframe>
    )

}

export default Thg;