import React, {Component} from "react";
import {NavLink} from "react-router-dom";

class Content extends Component {
    render() {
        return (
            <>

                <div className="container bgblack bgimage1 bgimage" id="pilotcharger">
                    <div className="content">
                        <div className="item padding-top-m">
                            <div className="col1 largetxt">
                                <h3>Der Pilot Charger erzeugt Naturstrom.</h3>
                                <p className="nomargin"> Vollkommen stromnetzunabh&auml;ngig, profitabel und schnell Laden! </p>
                                <div className="linkscontainer">
                                    <a className="mainbutton extbutton" href="thg.html"><p>THG Pr&auml;mie Lades&auml;ule:</p>bis zu 10 Cent/ kWh beim Pilot Charger erstattet bekommen</a>
                                    <a className="mainbutton extbutton" href="kauftdeinethg.html"><p>THG Pr&auml;mie E-Auto:</p>bis zu 150&euro; j&auml;hrlich f&uuml;r ihr E-Auto sichern</a>
                                </div>
                            </div>
                        </div>
                        <div className="item padding-top-m padding-bottom-l">
                            <div className="col1 activebox largetxt">
                                <div className="gap2rem">
                                </div>
                                <h3>Der Pilot Charger</h3>
                                <p className="nomargin"> Der Pilot Charger ist eine mobile und netzunabhängige Schnellladestation, die mittels Bio-Ethanol<br />
                                    CO<sub>2</sub> neutralen Naturstrom f&uuml;r Elektrofahrzeuge erzeugt. </p>

                                <div className="item itemcols padding-bottom-s">
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-up">
                                            <h5>Orts- und netzunabh&auml;ngig</h5>
                                            Keine Einschr&auml;nkungen bei der Standortwahl<br/> <strong>&#10132; Unbegrenzte
                                            Einsatzm&ouml;glichkeiten</strong>
                                        </div>
                                    </div>
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-up">
                                            <h5>Zeitunabh&auml;ngig</h5>
                                            Keine Vorlaufzeit durch Planung und umfassende Bauma&szlig;nahmen<br/><strong>&#10132; Sofortige
                                            Verf&uuml;gbarkeit</strong>
                                        </div>
                                    </div>
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-up">
                                            <h5>Kostenunabh&auml;ngig</h5>
                                            Kein Investment<br/> <strong>&#10132; Monatliche Miete ben&ouml;tigt keine
                                            Kapitalbindung</strong>
                                        </div>
                                    </div>
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-up">
                                            <h5>Proof of Concept</h5>
                                            ...flexibel bei der<br/>
                                            <strong>&#10132; Standortwahl</strong><br />
                                            <strong>&#10132; Abrechnung</strong> und bei steigender<br />
                                            <strong>&#10132; Kundennachfrage</strong>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container bgblack bgimage2 bgimage" id="challenge">
                    <div className="content">
                        <div className="item padding-top-l padding-bottom-l">
                            <div className="col1 activebox largetxt">
                                <div className="gap2rem">
                                </div>
                                <h3>Pilot Charger am BER Berlin</h3>
                                <div className="item padding-top-s padding-bottom-s">
                                    <iframe width="660" height="415" src="https://www.youtube.com/embed/oMx4HtIImsg"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen></iframe>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container bgblack">
                    <div className="content">
                        <div className="item itemcols padding-top-l padding-bottom-l">
                            <div className="col2 largetxt">
                                <div className="collitem leftalign">

                                    <h3>Die Eigenschaften des Pilot Chargers</h3>
                                    <ul className="ulheading normaltxt">
                                        <li>Mobile und netzunabh&auml;ngige Schnellladestation</li>
                                        <li>Verwendung von umweltfreundlichem Bio-Ethanol</li>
                                        <li>Patentierte CO<sub>2</sub> neutrale Stromerzeugung</li>
                                        <li>150 kW Ladeleistung</li>
                                        <li>Bis zu 300 km Reichweite in 15 Minuten</li>
                                        <li>Zwei Ladepunkte pro Station bei kleiner Standfl&auml;che (nur eine
                                            Parkplatzgr&ouml;&szlig;e)
                                        </li>
                                        <li>Transportabel und flexibel in der Standortwahl</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col2">
                                <div className="collitem rightalign">
                                    <img src="images/wide-image2.jpg" alt="Image description"
                                         className="width100percent"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container bgblack bgimage2 bgimage" id="challenge">
                    <div className="content">
                        <div className="item padding-top-l padding-bottom-l">
                            <div className="col1 activebox largetxt">
                                <div className="gap2rem">
                                </div>
                                <h3>Die Herausforderung</h3>
                                <p className="nomargin"> Die heutigen Herausforderungen beim Ausbau der
                                    Ladeinfrastruktur &uuml;ber das bestehende Stromnetz. </p>

                                <div className="item itemcols padding-top-s padding-bottom-s">
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-down">
                                            <h5>Verf&uuml;gbarkeit</h5>
                                            Innerst&auml;dtisch <strong>gilt das Stromnetz</strong> bereits heute in vielen Teilen <strong>als
                                            &uuml;berlastet</strong>
                                        </div>
                                    </div>
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-down">
                                            <h5>Zeit</h5>
                                            Planung, Genehmigung und Umsetzung des <strong>Netzausbaus kann bis zu drei Jahren
                                            dauern</strong>
                                        </div>
                                    </div>
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-down">
                                            <h5>Standort</h5>
                                            Bestehende <strong>Unsicherheiten</strong> bei der Wahl nach geeigneten Standorten
                                        </div>
                                    </div>
                                    <div className="col4">
                                        <div className="collitem pc-feature thumbs-down">
                                            <h5>Wirtschaftlichkeit</h5>
                                            Ausbau und Aufbau der Ladeinfrastruktur wird durch <strong>hohe Investitionen </strong>
                                            beeintr&auml;chtigt
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container bgwhite">
                    <div className="content">
                        <div className="item padding-top-s largetxt">
                            <div className="col1">
                                <h3>Hintergrund</h3>
                                <p className="nomargin">Derzeitiger Ausbau der Schnellladeinfrastruktur hinkt dem
                                    Markthochlauf der E-Mobilit&auml;t hinterher.</p>
                            </div>
                        </div>
                        <div className="item itemcols padding-bottom-m pc-background">
                            <div className="col3">
                                <div className="collitem leftalign">
                                    <img src="images/istockphoto-1207681953-1024x1024.jpg" className="autoroundness"/>
                                    <h5>Zulassungszahlen f&uuml;r Elektrofahrzeuge steigen</h5>
                                    <ul className="ulheading">
                                        <li>J&auml;hrlicher Zuwachs von mehr als 60 % seit 2018</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col3">
                                <div className="collitem leftalign">
                                    <img src="images/istockphoto-942599318-1024x1024.jpg" className="autoroundness"/>
                                    <h5>Bis 2030 mehr als 15 Millionen Elektrofahrzeuge in Deutschland</h5>
                                    <ul className="ulheading">
                                        <li>Konventionelle Verbrennungsmotoren f&uuml;r PKW ab 2035 nicht mehr
                                            zulassungsf&auml;hig
                                        </li>
                                        <li>Diverse Fahrzeughersteller planen in den kommenden Jahren ihre gesamte Produktion auf elektrische Antriebe umzustellen
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col3">
                                <div className="collitem leftalign">
                                    <img src="images/istockphoto-1146066984-1024x1024.jpg" className="autoroundness"/>
                                    <h5>Massiver Ausbau der &ouml;ffentlichen und privaten Ladeinfrastruktur wird
                                        ben&ouml;tigt</h5>
                                    <ul className="ulheading">
                                        <li>Mehr als eine Million &ouml;ffentlich-zug&auml;ngliche Ladepunkte bis 2030</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container bgblack" id="solution">
                    <div className="content">
                        <div className="item padding-top-s largetxt">
                            <div className="col1">
                                <h3>Unsere L&ouml;sung</h3>
                                <p className="nomargin">Einsatzm&ouml;glichkeiten des Pilot Charger</p>
                            </div>
                        </div>
                        <div className="item itemcols padding-bottom-m">
                            <div className="col2">
                                <div className="collitem leftalign">
                                    <img src="images/narrow-image_3.jpg" alt="Pilot Charger" className="width80percent"/>
                                </div>
                            </div>
                            <div className="col2 largetxt">
                                <div className="collitem leftalign">
                                    <ul className="ulheading normaltxt">
                                        <li>Flexibles Testen der besten Standorte bez&uuml;glich der Kundenakzeptanz (Proof
                                            of Concept)
                                        </li>
                                        <li>Erm&ouml;glicht kurzfristige Umstellung des Fuhrparks auf Elektrofahrzeuge</li>
                                        <li>Schnelle Neukundengewinnung f&uuml;r Ladeinfrastrukturbetreiber oder
                                            Unternehmen
                                        </li>
                                        <li>Kurzfristige Reaktion auf Kundenanfragen
                                            <ul>
                                                <li>Erh&ouml;hter Druck seitens der Mieter auf Vermieter aufgrund fehlender
                                                    Ladem&ouml;glichkeiten
                                                </li>
                                                <li>Angeordnete Umstellung auf Elektrofahrzeuge in bspw.
                                                    Logistikunternehmen zur st&auml;dtischen Belieferung Ihrer Endkunden
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Bereitstellung von Schnellladestationen f&uuml;r kurze Standzeiten
                                            <ul>
                                                <li>(u.a. Tankstellen, Reparaturen in Werkst&auml;tten)</li>
                                            </ul>
                                        </li>
                                        <li>&Uuml;bergangsl&ouml;sung bis zur Fertigstellung station&auml;rer Ladestationen</li>
                                        <li>Tempor&auml;re Gro&szlig;veranstaltungen</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container bgwhite" id="offer">
                    <div className="content">
                        <div className="item padding-top-s largetxt">
                            <div className="col1">
                                <h3>Unser Angebot</h3>
                            </div>
                        </div>
                        <div className="item itemcols padding-bottom-s">
                            <div className="col2 largetxt">
                                <div className="collitem leftalign">
                                    <ul className="ulheading normaltxt">
                                        <li>Weniger als einen Monat von der Vertragsunterzeichnung bis zur Auslieferung
                                            und Inbetriebnahme
                                        </li>
                                        <li>Flexibles Mietmodell zwischen 12 und 60 Monaten</li>
                                        <li>Ladestation in eigenem Corporate Identity m&ouml;glich</li>
                                        <li>iOS und Android App als White Label L&ouml;sung
                                            <ul>
                                                <li>Alle verf&uuml;gbaren Ladestationen in einer Kartenansicht (mehr als
                                                    200.000 Ladepunkte in Deutschland)
                                                </li>
                                                <li>Flexible und integrierte Kundenabrechnung</li>
                                            </ul>
                                        </li>
                                        <li>Kundenhotline in Deutsch und Englisch
                                            <ul>
                                                <li>Weitere Sprachen in Niederl&auml;ndisch, Franz&ouml;sisch, Italienisch,
                                                    Spanisch, Norwegisch und Schwedisch m&ouml;glich
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col2">
                                <div className="collitem rightalign">
                                    <img src="images/narrow-image_2.jpg" alt="Pilot Charger"
                                         className="width80percent"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


export default Content;